import generateChartBar from './chart_bar.js'
import ApexCharts from 'apexcharts'

const defaultOptions = {
  chart: {
    height: 350,
    animations: {
      enabled: false
    },
  },
  dataLabels: {
    enabled: false
  },
  colors: ['#005a6fd9', '#f78418'],
  series: [],
  noData: {
    text: 'Caricamento in corso...'
  }
}

async function generateGraphApex(){
  var nodes = document.getElementsByClassName("chart-js");
  for (var i = 0; i < nodes.length; i++) {
    drawGraph(nodes[i])
  }
}

async function drawGraph(node){
  var chart = new ApexCharts(node, defaultOptions);
  chart.render();
  // Di default chiama la stessa pagina con .json alla fine
  // esempio: se sei sulla pagina /users/1, chiama /users/1.json)
  // in alternativa puoi specificare un url con data-url
  // esempio: <div class="chart" data-url="/users/1/other.json"></div>
  var url = node.dataset.url
  if (url == undefined){
    url = location.href+".json"
  }
  const response = await fetch(url)
  response.json().then(data => {
    chart.updateOptions({
      xaxis: {
        categories: data.months,
        labels: { formatter:
          function (value, tt, opts) {
            if(value != undefined && data.type == "bar")
              return value.substring(0,15)
            else
              return value
           }
        }
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            // formatta la cifra per la valuta italiana (es. 1.000,00 €)
            const str = new Intl.NumberFormat('it', { style: 'currency', currency: 'EUR' }).format(value)
            return str;
          }
        }
      },
      chart: {
        toolbar: { show: false },
        type: data.type,
      }
    })

    if (data.type == "line"){
      if(data.net_amounts.length == 0){
        chart.updateOptions({noData: {text: 'Nessun dato disponibile'}})
      } else {
        chart.appendSeries({ name: 'Incasso', data: data.net_amounts})
        if(data.net_amounts_without_bp_visible){
          chart.appendSeries({ name: 'Incasso senza Betterplazed', data: data.net_amounts_without_bp})
        }
      }
    }

    if (data.type == "bar") {
      generateChartBar(chart, data)
    }
  })
}

export default generateGraphApex