import {Turbo} from '@hotwired/turbo-rails'
import generateGraphApex from '@/graphs/generate_graph_apex.js'
import { Tooltip  } from 'bootstrap';
import '~/index.scss'

document.addEventListener("turbo:load", function () {
  generateGraphApex()

  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
  const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl))
})
